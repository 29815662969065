.swiper.swiper-city {
  width: 100%;
  overflow: visible;

  &.centered-content {
    .swiper-wrapper {
      justify-content: center;
    }
  }

  .swiper-slide {
    min-width: 150px;
  }
}

.swiper.famous-brands {
  .swiper-wrapper {
    align-items: center;

    img {
      width: 100%;
      max-height: 60px;
      margin: 0 auto;
      object-fit: contain;
    }
  }
}

.swiper.feedbacks {
  .swiper-button-next {
    right: 0;
  }

  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-next, .swiper-button-prev {
    color: #000000;

    &:after {
      font-size: 32px;
    }
  }
}

.swiper.spots {
  .swiper-pagination {
    position: absolute;
    z-index: 2;
    right: 20px;
    bottom: 20px;
    font-size: 20px;
    color: #FFFFFF;
  }

  .swiper-button-prev, .swiper-button-next {
    width: 70px;
    height: 50px;
    top: 50%;
    margin-top: initial;
    border: 2px solid #FFFFFF;
    border-radius: 30px;
    color: #000000;
    outline: none;
    transition: .3s;

    &:after {
      content: url(../../../assets/icons/carousel-navigation-arrow.svg);
      font-size: 0;
      transition: inherit;
      background: center center no-repeat;
      background-size: cover;
    }

    &:hover {
      width: 90px;

      &:after {
        transform: scaleX(1.2);
      }
    }
  }

  .swiper-button-prev {
    left: 10px;
    transform: translateY(-50%) rotate(180deg);
  }

  .swiper-button-next {
    right: 10px;
    transform: translateY(-50%);
  }
}

.swiper.available-weeks {
  //25px is space for single navigation
  max-width: calc(100% - 25px);
  margin-left: 0;

  @media (min-width: 600px) {
    max-width: calc(100% - 60px);
  }

  @media (min-width: 900px) {
    max-width: calc(100% - 80px);
  }
}
